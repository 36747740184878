<template>
  <div>
    <Article v-if="$route.path == '/home/store/article'" />
    <!-- Article  $t('article')-->
    <Sound v-if="$route.path == '/home/store/sound'" />
    <!-- Sound $t('sound')-->
    <Video v-if="$route.path == '/home/store/video'" />
    <!-- Video $t('video') -->
  </div>
</template>

<script>
import Article from "./store_Article/article-index.vue";
import Sound from "./store_Sound/sound-index.vue";

import Video from "./store_Video/video-index.vue";
export default {
  components: {
    Article,
    Sound,
    Video,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
