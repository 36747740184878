<template>
  <div>
    <Breadcrumbs page_title="article" :items="breadcrumbs" />
    <v-container fluid>
      <v-card>
        <v-row justify="space-between" class="ma-1 pa-2">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            cols="12"
            lg="6"
            md="12"
            sm="12"
            class="storecol"
            v-for="(article, i) in articleList"
            :key="i"
          >
            <v-list>
              <v-list-item>
                <v-col cols="3">
                  <v-img
                    :src="article.picturebackground"
                    style="
                      height: 120px;
                      width: 300px;
                      border-radius: 10px !important;
                    "
                  ></v-img>
                </v-col>
                <v-col cols="4" lg="4" class="content">
                  <v-list-item-content>
                    <v-list-item-content class="title">
                      {{ article.contentName }}</v-list-item-content
                    >
                    <v-list-item-subtitle class="subtitle">
                      {{ article.wordDescription }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-col>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn
                      dark
                      depressed
                      class="store_btn text-capitalize white--text mr-3"
                      color="#A6CC39"
                      style="border-radius: 8px !important"
                      @click="dialogopen(article)"
                    >
                      <v-icon style="color: #ffffff" class="mr-1" :small="true"
                        >mdi-plus-box-outline</v-icon
                      >
                      <span style="font-size: 14px; color: #ffffff">{{
                        $t("addlibrary")
                      }}</span>
                    </v-btn>
                    <v-btn
                      dark
                      depressed
                      class="store_btn text-capitalize white--text"
                      color="#A6CC39"
                      style="border-radius: 8px !important"
                      @click="change(article.id)"
                    >
                      <v-icon style="color: #ffffff" class="mr-1" :small="true"
                        >mdi-eye-outline</v-icon
                      >
                      <span style="font-size: 14px; color: #ffffff">{{
                        $t("view")
                      }}</span>
                    </v-btn>
                  </div>

                  <v-list-item-subtitle class="numberofview mt-5">
                    {{ article.countView }}
                    {{ article.countView > 1 ? $t("views") : $t("view") }}
                  </v-list-item-subtitle>
                </v-col>
              </v-list-item>
            </v-list>
            <div class="test">
              <div class="border1"></div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            width="700"
            height="800"
            transition="dialog-bottom-transition"
            id="dialogcard"
          >
            <v-card>
              <v-card-title>
                <label for="Library">
                  <v-icon>mdi-plus-box-outline</v-icon> Add Library</label
                >
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-2"
                  color="blue-lighten-2"
                  @click="dialog = false"
                  plain
                >
                  <v-icon>mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-divider></v-divider>
              <br />
              <v-card-text>
                <div>
                  <v-col sm="12" lg="6">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      style="border-radius: 8px; border-color: #c0c0c0"
                      clearable
                      prepend-inner-icon="mdi-magnify"
                      placeholder="search..."
                      v-model="search"
                    >
                    </v-text-field>
                  </v-col>

                  <span class="pl-6" style="color: #ff6060; font-size: 18px"
                    >*{{ $t("OnlyoneCat") }}</span
                  >
                  <template>
                    <v-radio-group name="myGroup">
                      <v-data-table
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        :headers="headers"
                        :items="topicItems"
                        :search="search"
                        hide-default-footer
                        @page-count="pageCount = $event"
                        :page.sync="page"
                        class="user-table pa-6"
                        v-model="selected"
                        item-key="id"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td>
                              <v-radio
                                @change="selectItem(item)"
                                :value="item.id"
                                :input-value="selectedItem === item"
                                name="selectedItem"
                              ></v-radio>
                            </td>

                            <td>{{ item.topicName }}</td>
                            <td color="primary" v-if="item.status == true">
                              <span
                                style="font-size: 16px; color: #a6cc39"
                                class="text-capitalize"
                                >{{ "Active" }}</span
                              >
                            </td>
                            <td v-else class="text-capitalize">
                              <span style="font-size: 16px; color: #d52a2a">{{
                                "Inactive"
                              }}</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-radio-group>
                  </template>
                  <v-card elevation="0">
                    <v-col cols="auto" class="d-flex justify-end"
                      ><v-pagination
                        circle
                        class="pagination"
                        v-model="page"
                        :length="pageCount"
                      ></v-pagination
                    ></v-col>
                    <v-divider></v-divider>
                    <v-card-actions class="mt-3 pb-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        class="text-capitalize btnfont-style"
                        width="100"
                        style="
                          border: 1px solid #c0c0c0;
                          border-radius: 8px;
                          color: #424242;
                        "
                        @click="closeDialog"
                        >{{ $t("cancel") }}</v-btn
                      >
                      <v-btn
                        width="100"
                        class="text-capitalize btnfont-style btn_hover_effect"
                        style="
                          color: #ffff;
                          border: 1px solid #a6cc39;
                          border-radius: 8px;
                        "
                        color="#A6CC39"
                        @click="selectArticle()"
                        >{{ $t("submit") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>

        <!-- Confirm Add to library  -->

        <v-dialog
          height="800px"
          class="rounded-lg"
          width="705"
          v-model="Confirmdialog"
        >
          <v-card justify="center">
            <div v-if="selectedItem">
              <v-card-title
                class="confirmtitle"
                style="justify-content: center"
              >
                would you like to add article to the
                {{ selectedItem.topicName }} category?</v-card-title
              >
              <div class="d-flex justify-center align-center">
                <v-progress-circular
                  v-if="actionloading"
                  indeterminate
                  class="loading-circle"
                  color="green"
                ></v-progress-circular>
              </div>
              <v-card-actions class="d-flex justify-center pb-5">
                <v-btn
                  text
                  class="text-capitalize btnfont-style"
                  width="70"
                  height="37"
                  style="
                    border: 1px solid #c0c0c0;
                    border-radius: 8px;
                    color: #424242;
                    margin-right: 65px;
                    font-size: 16px;
                  "
                  @click="NoLibraryClick()"
                  >No</v-btn
                >
                <v-btn
                  width="70"
                  height="37"
                  class="text-capitalize btnfont-style btn_hover_effect"
                  style="
                    color: #ffff;
                    border: 1px solid #a6cc39;
                    border-radius: 8px;
                    font-size: 16px;
                  "
                  color="#A6CC39"
                  @click="AddLibrary()"
                  >Yes</v-btn
                >
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <div>
          <v-dialog
            height="800px"
            class="rounded-lg"
            width="705"
            v-model="Confirmdialogalert"
          >
            <v-card justify="center">
              <div v-if="selectedItem">
                <v-card-title
                  class="confirmtitle"
                  style="justify-content: center"
                >
                  <span>
                    The article was successfully added to the library.</span
                  >
                  Do you want to go directly to the added content page?
                </v-card-title>

                <v-card-actions class="d-flex justify-center pb-5">
                  <v-btn
                    text
                    class="text-capitalize btnfont-style"
                    width="70"
                    height="37"
                    style="
                      border: 1px solid #c0c0c0;
                      border-radius: 8px;
                      color: #424242;
                      margin-right: 65px;
                      font-size: 16px;
                    "
                    @click="Confirmdialogalert = false"
                    >No</v-btn
                  >
                  <v-btn
                    width="70"
                    height="37"
                    class="text-capitalize btnfont-style btn_hover_effect"
                    style="
                      color: #ffff;
                      border: 1px solid #a6cc39;
                      border-radius: 8px;
                      font-size: 16px;
                    "
                    color="#A6CC39"
                    @click="
                      $router.push({
                        path:
                          '/home/library/' +
                          selectedItem.topicName.split('?').join('') +
                          '/Article',
                        query: {
                          topic_name: selectedItem.topicName,
                        },
                      }),
                        saveLibraryId(selectedItem.id)
                    "
                    >Yes</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </v-container>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import router from "../../../../../router";
import * as moment from "moment/moment";

export default {
  components: {},

  data() {
    return {
      permissionDialog: false,
      permissionMessage: "",
      page: 1,
      pageCount: 0,
      selectedItem: [],
      radioGroup: 1,
      search: "",
      articleItems: null,
      articleList: [],
      dialogm1: "",
      Confirmdialog: false,
      Confirmdialogalert: false,
      actionloading: false,
      View: "",

      dialog: false,
      selectedValue: "",
      selected: [],
      articleLibray: [],
      alert: false,

      headers: [
        {
          value: [],
          class: "tableColor",
          align: "left",
          width: "60px",
        },
        {
          text: "category",
          align: "left",
          value: "topicName",
          class: "tableColor",
          width: "160px",
        },
        {
          text: "status",
          align: "left",
          value: "status",
          class: "tableColor",
          width: "130px",
        },
      ],

      topicItems: [],
    };
  },

  computed: {
    breadcrumbs() {
      return [
        {
          sidebar_tilte: "home",
        },
        {
          text: "store",
          href: "/home/store",
        },
        {
          text: "article",
          href: "/home/article",
        },
      ];
    },
  },
  created() {
    this.getArticle();
    this.count();
  },
  watch: {
    search() {
      this.searchMeth();
    },
  },
  methods: {
    saveLibraryId(id) {
      localStorage.setItem("libraryId", id);
    },
    async searchMeth() {
      if (this.search == "") {
        this.articleList = this.articleItems;
      } else {
        this.articleList = this.articleItems.filter((name) =>
          name.contentName.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
    count() {
      if (this.articleList.countView == 0 && this.articleList.countView == 1) {
        this.view = this.View;
      } else {
        this.view = this.Views;
      }
    },
    async Actions() {
      this.addLibray = true;
    },
    async getArticle() {
      let self = this;
      self.loading = true;
      axios
        .get(`${self.web_url}Contents/GetWordContentForProductAdmin`)
        .then(function (response) {
          self.articleItems = response.data.data.map((v, i) => ({
            ...v,
            no: i + 1,
            createDate: moment(v.createDate).local().format("DD/MM/YYYY"),
          }));
          self.loading = false;
          self.articleList = self.articleItems;
        })
        .catch(function () {});
    },
    async dialogopen(data) {
      let self = this;
      let checkPermission = self.check_Permissions("store");
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to add Article from Library.";
      } else {
        self.dialog = true;
        self.articleLibray = data;
        self.loading = true;
        await axios
          .get(
            `${self.web_url}Topic/GetTopics?id=` +
              localStorage.getItem("companyID")
          )
          .then(function (response) {
            self.topicItems = response.data.data;
            self.loading = false;
          })
          .catch(function (res) {
            alert("error", res);
          });
      }
    },
    closeDialog() {
      this.dialog = false;
      this.selectedItem = [];
    },
    change(data) {
      router.push({
        name: "articleview",
        query: { data },
      });
    },

    close() {
      this.addLibraryDialog = false;
    },
    NoLibraryClick() {
      let self = this;
      self.Confirmdialog = false;
      self.selectedItem = [];
    },
    async AddLibrary() {
      let self = this;
      self.actionloading = true;
      const userdata = JSON.parse(
        localStorage.getItem("vivek_authenticated_user_data")
      );
      const memberid = userdata.memberId;
      let request = {
        id: self.articleLibray.id,
        topicID: self.selectedItem.id,
        createBy: memberid,
        companyID: localStorage.getItem("companyID"),
        contentName: self.articleLibray.contentName,
      };
      await self.$axios
        .post(`${self.web_url}Contents/AddArticleFromStoreToLibrary`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            self.Confirmdialog = false;
            self.Confirmdialogalert = true;
          }
        })
        .catch(function (res) {
          alert(res);
        });
    },
    selectArticle() {
      let self = this;
      if (self.selectedItem.length === 0) {
        alert("Please select one category!");
      } else {
        self.Confirmdialog = true;
      }
    },
    selectItem(itemId) {
      this.selectedItem = itemId;
    },
  },
};
</script>

<style>
@media (min-width: 1440px) and (max-width: 2560px) {
  .lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (min-width: 1264px) and (max-width: 1903px) {
  .storecol {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .content {
    width: 45%;
    max-width: 45%;
    flex-basis: 45%;
  }
  .btnname {
    margin: right;
  }
  .orgImage {
    width: 60px;
    height: 37px;
    border-radius: 20px !important;
  }
  .btncontainer {
    text-align: right;
  }
}
</style>

<style scoped>
.test {
  display: flex;
  justify-content: center;
}

.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.cardTitle {
  position: relative;
  color: #fff;
  font-size: 23px;
  justify-content: center;
  top: 40px;
}
.icons {
  position: absolute;
  bottom: -13px;
  right: -10px;
}
.v-card {
  border-radius: 20px;
}
.title {
  margin-bottom: 20px;
  font-size: 20px !important;
  color: #424242;
}
.store_btn {
  top: -20px !important;
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.store_btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.store_btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.store_btn:hover {
  transition: 0.25s;
}
.btnnames:hover {
  background-color: #4fb14e;
}
.store_btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
.numberofview {
  margin-right: 10px !important;
  display: flex !important;
  justify-content: end;
  color: #424242;
  font-size: 18px !important;
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  border-radius: 20px !important;
}

::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .faq-table tr:nth-child(even) {
  background: #e0e0e0;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
  word-break: break-all;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
.v-text-field__details {
  display: none !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child {
  border-radius: 8px 0 0 8px;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:last-child {
  border-radius: 0 8px 8px 0;
}
</style>
